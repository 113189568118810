import React, { useState } from 'react'
import styled from 'styled-components'
import SubmitButton from '../../atoms/SubmitButton'
import TextForm from '../../atoms/TextForm'
import RadioButton from '../../atoms/RadioButton'
import CheckBox from '../../atoms/CheckBox'
import Error from '../../atoms/share/Error'
import { createCompany } from '../../../api/company'

export const Signup = () => {
  const [name, setName] = useState("")
  const [staffName, setStaffName] = useState("")
  const [email, setEmail] = useState("")
  const [cell, setCell] = useState("")
  const [password, setPassword] = useState("")
  const [plan, setPlan] = useState(null)
  const [hasOption, setHasOption] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, serError] = useState("")

  const handleSubmit = () => {
    setLoading(true)
    createCompany(name, staffName, email, cell, password, plan, hasOption)
      .then(() => {
        document.location.href = '/management'
      })
      .catch((res) => {
        serError(res.response.data.message)
        setLoading(false)
      })
  }

  const handleChange = e => {
    if (Boolean(hasOption) === true) {
      setHasOption(false);
    } else {
      setHasOption(e.target.value);
    }
  };

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <SignupArea>
      <Top>
        <Title>新規登録</Title>
        <Description>
          JOB SMARTの新規登録フォームです。<br />
          下記をご記入の上、アカウントを開設くださいませ。
        </Description>
        <Notice>
          ※アカウント発行日よりプラン料金が課金されますのでご留意くださいませ<br />
          （1月15日に6ヶ月プランのアカウントを開設しますと7月15日までのご利用となります）
        </Notice>
      </Top>
      <Warning>{errorMessage()}</Warning>
      <Form>
        <Item>
          <Label>会社名/屋号</Label>
          <TextForm type="text" placeholder="株式会社JOB SMART" value={name} onChange={(e) => setName(e.target.value)} />
        </Item>
        <Item>
          <Label>ご担当者名</Label>
          <TextForm type="text" placeholder="山田太郎" value={staffName} onChange={(e) => setStaffName(e.target.value)} />
        </Item>
        <Item>
          <Label>電話番号</Label>
          <TextForm type="tel" placeholder="01234567890" value={cell} onChange={(e) => setCell(e.target.value)} />
        </Item>
        <Item>
          <Label>メールアドレス</Label>
          <TextForm type="email" placeholder="mail@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Item>
        <Item>
          <Label>パスワード</Label>
          <TextForm type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Item>
        <Item>
          <Label>導入プラン</Label>
          <Box>
            <RadioButton text="1ヶ月プラン" name='plan' value='1' onChange={(e) => setPlan(e.target.value)} />
            <RadioButton text="6ヶ月プラン" name='plan' value='2' onChange={(e) => setPlan(e.target.value)} />
            <RadioButton text="年間プラン" name='plan' value='3' onChange={(e) => setPlan(e.target.value)} />
          </Box>
        </Item>
        <Item>
          <Label>オプション希望</Label>
          <Box>
            <CheckBox text="希望する" onChange={handleChange} />
          </Box>
        </Item>
        <ButtonBox>
          <SubmitButton
            text="アカウントを開設する"
            onClick={handleSubmit}
            disabled={!name || !staffName || !email || !password || !plan || loading}
          />
        </ButtonBox>
      </Form>
    </SignupArea>
  )
}

const SignupArea = styled.div`
  background: #F1F6F9;
  margin: 5vh 200px 4vh;
  padding: 8vh 8vw 10vh;
  font-family: sans-serif,'Noto Sans JP';
  @media screen and (max-width: 1000px) {
    width: 65%;
    height: 100%;
    margin: 0 auto 5rem;
  }
`
const Top = styled.div`
  text-align: center;
`
const Title = styled.h2`
  color: #14274E;
  font-size: 24px;
  @media screen and (max-width: 1000px) {
    font-size: 2rem;
  }
`
const Description = styled.p`
  font-size: 20px;
  margin: 0;
`
const Notice = styled.p`
  color: #6F7070;
  font-size: 16px;
  margin: 0;
`
const Warning = styled.div`
  text-align: center;
`
const Form = styled.form`
  margin: 76px 100px 0;
  @media screen and (max-width: 1000px) {
    margin: 40px auto 0;
    font-size: 1.2rem;
    padding: 3rem 0 0;
  }
`
const Item = styled.div`
  display: flex;
  line-height: 43px;
  list-style: none;
  margin-bottom: 24px;
`
const Label = styled.label`
  color: #707071;
  text-align: center;
  width: 30%;
  @media screen and (max-width: 1000px) {
    width: 35%;
    padding-right: 2rem;
  }
`
const Box = styled.div`
  display: grid;
  width: 100%;
`
const ButtonBox = styled.div`
  text-align: center;
  margin: 75px 0 0 0;
`

export default Signup
