import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import StaffList from '../../../organisms/management/staff/StaffList'
import DepartmentList from '../../../organisms/management/departments/DepartmentList'
import EmploymentStatusList from '../../../organisms/management/employment_statuses/EmploymentStatusList'

export const Index = () => {
  return (
    <IndexArea>
      <StaffArea>
        <TitleArea>
          <Title value='スタッフ一覧' />
        </TitleArea>
        <StaffList />
      </StaffArea>
      <DepartmentArea>
        <TitleArea>
          <Title value='グループ一覧' />
        </TitleArea>
        <DepartmentList />
      </DepartmentArea>
      <EmploymentStatusArea>
        <TitleArea>
          <Title value='種別一覧' />
        </TitleArea>
        <EmploymentStatusList />
      </EmploymentStatusArea>
    </IndexArea>
  )
}

const IndexArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`
const StaffArea = styled.div``
const DepartmentArea = styled.div``
const EmploymentStatusArea = styled.div``
const TitleArea = styled.div``

export default Index
