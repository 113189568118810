import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import TableHead from '../../../atoms/share/TableHead'
import TableBody from '../../../atoms/share/TableBody'
import LinkButton from '../../../atoms/share/LinkButton'
import SecondaryButton from '../../../atoms/share/SecondaryButton'
import { fetchDepartments, deleteDepartment } from '../../../../api/department'

export const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    fetchDepartments()
      .then((res) => {
        setDepartments(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      });
  }, []);

  const heads = ['グループ名', '', '']

  const handleDelete = (id: number) => {
    deleteDepartment(id)
      .then((res) => {
        setDepartments(
          departments.filter(item =>
            item.id !== res.data.id
          )
        )
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      });
  }

  return (
    <DepartmentListArea>
      <ButtonBox>
        <LinkButton text='新規登録' path='/management/departments/new' />
      </ButtonBox>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {departments.map((department, index) => (
            <Row key={index}>
              <TableBody>{department.name}</TableBody>
              <TableBody>
                <TableButtonBox>
                  <LinkButton path={`/management/departments/${department.id}/edit`} text='編集' />
                </TableButtonBox>
              </TableBody>
              <TableBody>
                <TableButtonBox>
                  <SecondaryButton text='削除' onClick={() => handleDelete(department.id)} />
                </TableButtonBox>
              </TableBody>
            </Row>
          ))}
        </Tbody>
      </Table>
    </DepartmentListArea>
  )
}

const DepartmentListArea = styled.div`
  margin: 0 0 100px 0;
`
const Table = styled.table`
  border-collapse: collapse;
  margin: 0 auto;
  width: 80%;
  table-layout: fixed;
`
const Tbody = styled.tbody`
  display: contents;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 300px;
`
const Row = styled.tr``
const ButtonBox = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: right;
  margin-bottom: 30px;
  font-size: 16px;
`
const TableButtonBox = styled.div`
  width: 25%;
`


export default DepartmentList
