import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import EmploymentStatusForm from '../../../organisms/management/employment_statuses/EmploymentStatusForm'

export const New = () => {
  return (
    <NewArea>
      <Title value="スタッフ種別新規登録" />
      <EmploymentStatusForm />
    </NewArea>
  )
}

export const NewArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`

export default New
