import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import SubmitButton from '../../../atoms/SubmitButton'
import Info from '../../../atoms/share/Info'
import Error from '../../../atoms/share/Error'
import { fetchDepartments } from '../../../../api/department'
import { fetchEmploymentStatuses } from '../../../../api/employment_status'
import { createBreakTime } from '../../../../api/break_time'
import { hours, minutes } from '../../../../modules/time'

export const Form = () => {
  type Properties = {
    id: string, name: string
  }
  const [departments, setDepartments] = useState<Properties[]>([]);
  const [employmentStatuses, setEmploymentStatuses] = useState<Properties[]>([]);
  const [department, setDepartment] = useState<Properties>();
  const [employmentStatus, setEmploymentStatus] = useState<Properties>();
  const [conditionHour, setConditionHour] = useState(null)
  const [conditionMinute, setConditionMinute] = useState(null)
  const [brakeHour, setBrakeHour] = useState(null)
  const [brakeMinute, setBrakeMinute] = useState(null)
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true)
    fetchDepartments()
      .then((res) => { setDepartments(res.data) })
    fetchEmploymentStatuses()
      .then((res) => { setEmploymentStatuses(res.data) })
    setLoading(false)
  }, []);

  const handleSubmit = async() => {
    setInfo('')
    setError('')
    setLoading(true)
    await createBreakTime(conditionHour?.value, conditionMinute?.value, brakeHour?.value, brakeMinute?.value, Number(department?.id), Number(employmentStatus?.id))
      .then(() => {
        setInfo('設定が完了しました。')
      })
      .catch(() => {
        setError('設定に不備があります。')
      })
    setLoading(false)
  }

  const infoMessage = () => {
    if (info !== "") {
      return <Info>{info}</Info>
    }
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <FormArea>
      <Notice>{infoMessage()}{errorMessage()}</Notice>
      <FormBody>
        <Item>
          <Label>所属グループ</Label>
          <SelectBox>
            <Select
              value={department}
              onChange={setDepartment}
              options={departments}
              getOptionLabel={(option: Properties) => option.name}
              getOptionValue={(option: Properties) => option.id}
            />
          </SelectBox>
        </Item>
        <Item>
          <Label>スタッフ種別</Label>
          <SelectBox>
            <Select
              value={employmentStatus}
              onChange={setEmploymentStatus}
              options={employmentStatuses}
              getOptionLabel={(option: Properties) => option.name}
              getOptionValue={(option: Properties) => option.id}
            />
          </SelectBox>
        </Item>
        <Section>
          <Item>
            <Label><OriginalLabel>{department != undefined ? department.name : '全て'}/{employmentStatus != undefined ? employmentStatus.name : '全て'}の設定</OriginalLabel></Label>
          </Item>
          <Item>
            <Label>休憩時間</Label>
            <SelectBox>
              <Select value={conditionHour} onChange={setConditionHour} options={hours()} />時間
              <Select value={conditionMinute} onChange={setConditionMinute} options={minutes} />分以上の拘束時間で
              <Select value={brakeHour} onChange={setBrakeHour} options={hours(true)} />時間
              <Select value={brakeMinute} onChange={setBrakeMinute} options={minutes} />分の休憩とする
            </SelectBox>
          </Item>
        </Section>
        <ButtonBox>
          <SubmitButton
            text="保存"
            onClick={handleSubmit}
            disabled={loading || !conditionHour || !conditionMinute || !brakeHour || !brakeMinute}
          />
        </ButtonBox>
      </FormBody>
    </FormArea>
  )
}

const FormArea = styled.div`
  font-size: 16px;
`
const Notice = styled.div`
  text-align: center;
`
const FormBody = styled.form`
  margin: 12vh 0 100px;
`
const Item = styled.div`
  align-items: center;
  display: flex;
  list-style: none;
  margin-bottom: 24px;
`
const Label = styled.label`
  color: #707071;
  text-align: center;
  width: 25%;
  font-size: 16px;
`
const SelectBox = styled.div`
  display: flex;
  margin: 0 5px;
  align-items: center;
`
const Section = styled.div`
  margin-top: 10vh;
`
const ButtonBox = styled.div`
  width: 100%;
  margin: 75px auto 0;
  text-align: center;
`
const OriginalLabel = styled.div`
  color: #333333;
  font-weight: bold;
  text-align: left;
  padding: 0 0 0 10%;
  margin: 20px 0 10px 0;
`

export default Form
