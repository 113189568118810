import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import TextForm from '../../../atoms/TextForm'
import Info from '../../../atoms/share/Info'
import Error from '../../../atoms/share/Error'
import SubmitButton from '../../../atoms/SubmitButton'
import { createEmploymentStatus, updateEmploymentStatus } from '../../../../api/employment_status'

export interface Props {
  employment_status?: {
    id: number
    name: string
  }
}

export const EmploymentStatusForm: React.FC<Props> = ({ employment_status }) => {
  const [id, setId] = useState(null)
  const [name, setName] = useState('')
  const [info, setInfo] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (employment_status !== undefined) {
      setId(employment_status.id)
      setName(employment_status.name)
    }
  }, []);

  const handleSubmit = async() => {
    setLoading(true)
    if (document.location.pathname === '/management/employment_statuses/new') {
      await createEmploymentStatus(name)
        .then(() => {
          document.location.href = '/management/staff'
        })
        .catch(() => {
          setError('登録内容に不備があります。')
        })
    } else {
      await updateEmploymentStatus(id, name)
        .then(() => {
          setInfo('更新しました')
        })
        .catch(() => {
          setError('登録内容に不備があります。')
        })
    }
    setLoading(false)
  }

  const infoMessage = () => {
    if (info !== "") {
      return <Info>{info}</Info>
    }
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <EmploymentStatusFormArea>
      <Notice>{infoMessage()}{errorMessage()}</Notice>
      <Item>
        <Label>スタッフ種別名</Label>
        <NameForm>
          <TextForm type="text" placeholder="例）業務委託、正社員 など" value={name} onChange={(e) => setName(e.target.value)} />
        </NameForm>
      </Item>
      <ButtonBox>
        <SubmitButton
          text={document.location.pathname === '/management/employment_statuses/new' ? '新規登録する' : '変更を適用する'}
          onClick={handleSubmit}
          disabled={!name || loading}
        />
      </ButtonBox>
    </EmploymentStatusFormArea>
  )
}

export const EmploymentStatusFormArea = styled.form``
const Notice = styled.div`
  text-align: center;
`
const Item = styled.div`
  display: flex;
  line-height: 43px;
  list-style: none;
  margin: 0 auto 24px;
  justify-content: space-between;
  width: 40%;
`
const Label = styled.label`
  color: #707071;
  text-align: center;
  font-size: 16px;
`
const NameForm = styled.div`
  width: 70%;
`
const ButtonBox = styled.div`
  width: 100%;
  margin: 75px auto 0;
  text-align: center;
`

export default EmploymentStatusForm
